const styles = {
  closeButton: {
    marginTop: '-15px',
    marginRight: '-15px',
    float: 'right'
  },
  filterContainer: {
    width: '100%',
    background: 'white',
    transition: 'height 0.3s ease',
    zIndex: 3
  },
  titleHeader: {
    marginTop: '30px'
  },
  additionalItem: {
    marginTop: '10px',
    listStyleType: 'none'
  },
  sliderStyle: {
    position: 'relative',
    width: '90%',
    height: 40,
    margin: 'auto'
  },
  railStyle: {
    position: 'absolute',
    width: '100%',
    height: 10,
    marginTop: 15,
    borderRadius: 10,
    backgroundColor: '#ccc'
  },
  handleStyle: {
    position: 'absolute',
    marginLeft: -10,
    marginTop: 10,
    zIndex: 2,
    width: 20,
    height: 20,
    border: 0,
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: '#fff',
    color: '#333',
    border: '1px solid #00A8C6',
    left: null
  },
  trackStyle: {
    position: 'absolute',
    height: 10,
    zIndex: 1,
    marginTop: 15,
    backgroundColor: '#00A8C6',
    borderRadius: 2,
    cursor: 'pointer',
    left: null,
    width: null
  },
  tickStyle: {
    position: 'absolute',
    marginTop: 24,
    marginLeft: -0.5,
    width: 1,
    height: 8,
    backgroundColor: 'silver',
    left: null
  },
  tickValueStyle: {
    position: 'absolute',
    marginTop: 32,
    fontSize: 10,
    textAlign: 'center',
    marginLeft: null,
    width: null,
    left: null
  },
  mapMarker: {
    position: 'absolute',
    top: '-16px',
    left: '-30px',
    cursor: 'pointer'
  },
  mapLabel: {
    position: 'absolute',
    left: '-21px',
    zIndex: 1000,
    backgroundColor: 'white',
    fontSize: '14px',
    color: '#00A8C6',
    textShadow: '0 -1px 0 rgba(0, 0, 0, 0.3)',
    padding: '3px'
  },
  mapInfoWindow: {
    position: 'relative',
    width: '360px',
    bottom: '210px',
    left: '-180px',
    backgroundColor: 'white',
    zIndex: 10000,
    cursor: 'pointer',
    border: '3px solid #00A8C6',
    borderRadius: '0.5rem'
  }
}

export default styles
